import classNames from "classnames";
import { useStyles } from "./style";

const FooterComponent = () => {
  const classes = useStyles();
  return (
    <div className={classNames(classes.footer)}>
      <div className="container">
        <p className="smHeading">
          © Copyright 2024 | All Rights Reserved by <span>PocketPay Finance</span>
        </p>
      </div>
    </div>
  );
};

export default FooterComponent;

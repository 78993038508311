import React from "react";
import prototype from "prop-types";
import classNames from "classnames";
import LabelComponent from "../LableComponent";
import { useStyles } from "./style";

const TextBoxComponent = ({
  error = "",
  label = "",
  subTitle = "",
  mandatory,
  title = "Textbox",
  className,
  extraClass,
  labelExtraIcon,
  loading,
  style,
  theme,
  readOnlyValue,
  ...rest
}: any) => {
  const classes = useStyles();
  return (
    <div
      className={classNames(classes.formGrp, extraClass)}
      style={style}
    >
      <div className='fgBox'>
        {label && (
          <LabelComponent>
            {label} {mandatory && <span className="text-danger">*</span>}
            {labelExtraIcon}
          </LabelComponent>
        )}
        {subTitle && <small>{subTitle}</small>}
        <input
          maxLength={250}
          aria-label={title ? title : label}
          aria-labelledby={title ? title : label}
          title={title ? title : label}
          className={classNames(className)}
          autoComplete="off"
          readOnly={readOnlyValue}
          {...rest}
        />
      </div>
      {error && <span className={classNames(classes.error)}>{error}</span>}
    </div>
  );
};
export default TextBoxComponent;

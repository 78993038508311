import React, { useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import { Col, Image, Row } from "antd";
import classNames from "classnames";
import { useWallet } from "@solana/wallet-adapter-react";
import { WalletMultiButton } from "@solana/wallet-adapter-react-ui";
import { publicKey } from "@metaplex-foundation/umi";
import { shortenAccountAddress } from "utils/helper";
import PocketDarkLogo from "assets/images/logo.svg";
import ButtonComponent from "components/FormsComponent/ButtonComponent";
import { useStyles } from "./style";

const HeaderComponent = () => {
  const wallet = useWallet();
  const { connected, disconnect } = useWallet();
  const classes = useStyles();
  let navigate = useNavigate();

  const onDisconnect = () => {
    localStorage.clear();
    disconnect();
  };

  return (
    <div className={classNames(classes.header)}>
      <div className="container">
        <div className={classNames(classes.headerContainer)}>
          <Row gutter={{ xs: 20, sm: 20, md: 20, lg: 20 }} align={"middle"} justify={"space-between"}>
            <Col className="gutter-row">
              <Image
                src={PocketDarkLogo}
                alt="PocketPay Finance"
                preview={false}
                className='cursorPointer'
                onClick={() => navigate("/")}
              />
            </Col>
            <Col className="gutter-row">
              <div className="dFlex gap20 alignCenter">
                <ButtonComponent btnSecondary
                  onClick={() => navigate("/referral-program")}
                >Referral-Program
                </ButtonComponent>
                {connected ? (
                  <ButtonComponent
                    className="cursor-pointer"
                    onClick={() => onDisconnect()}
                  >
                    {wallet.publicKey &&
                      shortenAccountAddress(publicKey(wallet.publicKey!))}{" "}
                    Disconnect
                  </ButtonComponent>
                ) : (
                  <WalletMultiButton />
                )}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
};

export default HeaderComponent;

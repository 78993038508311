import { Modal } from "antd";

const ModalComponent = ({ title, openable, closable, children, widthStyle, extraClass, isCloseIcon }: any) => {
  return (
    <Modal
      title={title}
      open={openable}
      onOk={closable && closable}
      onCancel={closable && closable}
      className={extraClass && extraClass}
      closeIcon={isCloseIcon && isCloseIcon}
      width={widthStyle}
      footer=""
    >
      {children}
    </Modal>
  );
};

export default ModalComponent;

import { createUseStyles } from "react-jss";
import mediaQuery from "assets/breakpoints";

export const useStyles = createUseStyles({
  btn: {
    overflow: "hidden",
    position: "relative",
    display: "inline-flex",
    justifyContent: "center",
    verticalAlign: "middle",
    alignItems: "center",
    color: "var(--white)",
    textAlign: "center",
    userSelect: "none",
    fontWeight: "600",
    fontSize: "16px",
    borderRadius: "10px",
    borderStyle: "solid",
    borderWidth:"1px",
    padding: "12px 20px",
    borderColor: "transparent",
    backgroundColor: "var(--purple)",
    transition: "all 0.3s ease-in-out",
    WebkitTransition: "all 0.3s ease-in-out",
    cursor: "pointer",
    fontFamily: '"poppins", sans-serif !important',
    [mediaQuery.SCREEN_SM_MAX]: {
      fontSize: "15px",
      padding: "12px 18px",
    },
    " *": {
      transition: "all 0.3s ease-in-out",
      WebkitTransition: "all 0.3s ease-in-out",
    },
    "&:before, &:after": {
      top: "0",
      left: "0",
      content: '""',
      height: "100%",
      position: "absolute",
      transform: "translateX(-100px) skewX(-15deg)",
      WebkitTransform: "translateX(-100px) skewX(-15deg)",
    },
    "&:before": {
      width: "60px",
      opacity: "0.5",
      filter: "blur(30px)",
      background: "rgba(255, 255, 255, 0.5)",
    },
    "&:after": {
      left: "30px",
      width: "30px",
      filter: "blur(5px)",
      background: "rgba(255, 255, 255, 0.2)",
    },
    "&.svgY": {
      "& svg": {
        transform: "rotate(-45deg)",
        WebkitTransform: "rotate(-45deg)",
      },
    },
    "&:hover": {
      "&:before, &:after": {
        transition: "all 0.9s ease",
        WebkitTransition: "all 0.9s ease",
        transform: "translateX(400px) skewX(-15deg)",
        WebkitTransform: "translateX(400px) skewX(-15deg)",
      },
      "&::before": {
        opacity: "1",
      },
      "&.svgAnimationYX": {
        "& svg": {
          animation: "svgAnimationYX 0.7s infinite ease-in-out",
        },
      },
      "&.svgAnimationX": {
        "& svg": {
          animation: "svgAnimationX 0.7s infinite ease-in-out",
        },
      },
      "&.svgAnimationY": {
        "& svg": {
          animation: "svgAnimationY 0.7s infinite ease-in-out",
        },
      },
      "&:active": {
        transform: "scale(0.95)",
        WebkitTransform: "scale(0.95)",
      },
    },
  },
  btnSecondary: {
    backgroundColor: "transparent",
    color: "var(--white)",
    borderColor: "var(--white)",
    "& svg": {
      "& > path": {
        fill: "var(--primary)",
      },
    },
    "&:hover, &.active": {
      backgroundColor: "transparent",
      color: "var(--white)",
      borderColor: "var(--white)",
      "& svg": {
        "& > path": {
          fill: "var(--white)",
        },
      },
    },
  },
  btnLight: {
    backgroundColor: "var(--grey)",
    color: "var(--white)",
    "&:hover, &.active": {
      backgroundColor: "var(--grey)",
      color: "var(--white)",
    },
  },
});

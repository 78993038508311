export const baseURL = "https://saleapi.pocketpay.finance/api";
export const localURL = "https://sale.pocketpay.finance";
export const solProgramId = "Ff1CNPMkxyTDstwAgvBGqv5KaGoAYivzg8PHw52oujmD";
export const solReceiverAddress =
  "B9XePrCjwrBau9N4iqBNoFX7kiuF8V4cYiwy6B2oy33G";
export const solDevnetURL =
  "https://devnet.helius-rpc.com/?api-key=af28767a-214e-498f-9b5a-67c30bb6ed81";
export const umiDevenetURL =
  "https://devnet.helius-rpc.com/?api-key=89fe15c6-d403-4de5-bb77-d66a6a66e615";
export const allocatedPPayAmount = 0.11;
export const usdcDeventProgramId =
  "Gh9ZwEmdLJ8DscKNTkTqPbNwLNNBjuSzaG9Vp2KGtKJr";

import { toast } from "react-toastify";
import copy from "copy-to-clipboard";
import axios from 'axios';

export const signerRedirect = "https://solscan.io/tx/"
export const pubKeyRedirect = "https://solscan.io/account/"

export const toastMessage = (data: any) =>
  toast(data, {
    position: "top-left",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    theme: "light",
  });

export const checkAllRequiredFieldsWithKey = (fields: any, values: any) =>
  Object.entries(fields).reduce((acc, [key]) => {
    const message = !values[key] ? "This field is required" : "";
    return { ...acc, [key]: message };
  }, {});

export const getStorageData = (key: string) => {
  try {
    const item = localStorage.getItem(key);
    if (item !== null) {
      return item;
    } else {
      return null;
    }
  } catch (err) {
    return null;
  }
};

export const setStorageData = (key: any, data: any) => {
  localStorage.setItem(key, data);
};

export function shortenAccountAddress(
  text: string,
  start = 4,
  end = 4,
  separator = ".."
): string {
  return !text
    ? ""
    : text.slice(0, start) + separator + (end ? text.slice(-end) : "");
}

export function shortenAddress(
  text: string,
  start = 4,
  end = 4,
  separator = "..."
): string {
  return !text
    ? ""
    : text.slice(0, start) + separator + (end ? text.slice(-end) : "");
}

export const parsedWithFixed = (getSting: string) => {
  if (getSting == null || getSting == undefined) {
    return "0.00"
  } else {
    return Number.parseFloat(getSting).toFixed(2);
  }
}

export const copyContent = (textToCopy: string) => {
  copy(textToCopy, {
    debug: true,
    message: "Press #{key} to copy",
  });
  toastMessage("Copied!");
};

/* https://api.coinbase.com */
export const identifyCoinAmount = async (setManageChainAmount: any) => {
  try {
    const requests = [
      axios.get("https://api.coinbase.com/v2/prices/Sol-USD/buy"),
      axios.get("https://api.coinbase.com/v2/prices/usdc-usd/buy"),
    ];
    const responses = await Promise.all(requests);
    responses.forEach((response, index) => {
      const { data } = response.data;
      const amount = data && data.amount ? parseFloat(data.amount) : 0;
      switch (index) {
        case 0:
          setManageChainAmount((prevAmount: any) => ({
            ...prevAmount,
            solana: amount,
          }));
          break;
        case 1:
          setManageChainAmount((prevAmount: any) => ({
            ...prevAmount,
            solanaUSDC: amount,
          }));
          break;
        default:
          break;
      }
    });
  } catch (error: any) { }
};

export const identifyCoinGetURL = (getCoinName: any) => {
  switch (getCoinName) {
    case "SOL": {
      return "https://api.coinbase.com/v2/prices/Sol-USD/buy";
    }
    case "SOL-USDC": {
      return "https://api.coinbase.com/v2/prices/usdc-usd/buy";
    }
    case "SOL-USDT": {
      return "https://api.coinbase.com/v2/prices/usdt-usd/buy";
    }
  }
};
import { none } from "@metaplex-foundation/umi";
import { createUseStyles } from "react-jss";
export const useStyles = createUseStyles({
  formGrp: {
    textAlign: "left",
    marginBottom: "0px",
    "& .fgBox": {
      display: "flex",
      flexDirection: "column",
      "& input": {
        width: "100%",
        border: "1px solid var(--cTextOpacity50)",
        borderRadius: "5px",
        backgroundColor: "transparent",
        color: " var(--white)",
        fontSize: " 16px",
        fontWeight: "600",
        padding: "15px",
        "&:focus-visible": {
          outline: "none",
          borderColor: "var(--cText)",
        },
      },
    },
  },
  error: {
    color: "var(--danger)",
  },
});

import { useEffect, useState } from "react";
import { Col, Row } from "antd";
import classNames from 'classnames';
import { useWallet } from '@solana/wallet-adapter-react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { CopyIcon } from 'svgIcon';
import { localURL } from 'utils/env';
import { copyContent, parsedWithFixed, shortenAddress } from 'utils/helper';
import ModalComponent from "components/FormsComponent/ModalComponent";
import InvestmentListComponent from "components/InvestmentListComponent";
import { useStyles } from './style';

const ContentComponent = ({ dataContent }: any) => {
  const classes = useStyles();
  const { connected } = useWallet();
  const [showHistory, setShowHistory] = useState(false);
  return (
    <>
      {connected && (
        <div className={classNames(classes.historyIconWrapper)}>
          <span onClick={() => setShowHistory(true)}>
            <p className="smHeading textUnderline cursorPointer textUpper">View history</p>
          </span>
        </div>
      )}
      <Row gutter={{ xs: 20, sm: 20, md: 20, lg: 20 }} >
        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          sm={{ span: 12 }}
          md={{ span: 12 }}
          lg={{ span: 12 }}>
          <div className="card dFlex flexCol justifyCenter alignCenter textCenter mb20">
            <p className="cText">Total Investment</p>
            <h2 className="lgHeading">$ {Object.keys(dataContent).length > 0 ? parsedWithFixed(dataContent.total_invested_amount) : "0.00"}</h2>
          </div>
        </Col>
        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          sm={{ span: 12 }}
          md={{ span: 12 }}
          lg={{ span: 12 }}>
          <div className="card dFlex flexCol justifyCenter alignCenter textCenter mb20">
            <p className="cText">Total PPAY Allocated</p>
            <h2 className="lgHeading">{Object.keys(dataContent).length > 0 ? parsedWithFixed(dataContent.total_ppay_allocated) : "0.00"}</h2>
          </div>
        </Col>
      </Row>
      <Row gutter={{ xs: 20, sm: 20, md: 20, lg: 20 }} className="mb20">
        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          sm={{ span: 12 }}
          md={{ span: 12 }}
          lg={{ span: 12 }}>
          <div className="card dFlex flexCol justifyCenter alignCenter textCenter">
            <p className="cText">Referral Earning</p>
            <h2 className="lgHeading">$ {Object.keys(dataContent).length > 0 ? parsedWithFixed(dataContent.total_refferal_amount) : "0.00"}</h2>
          </div>
        </Col>
        <Col
          className="gutter-row"
          xs={{ span: 24 }}
          sm={{ span: 12 }}
          md={{ span: 12 }}
          lg={{ span: 12 }}>
          <div className="card dFlex flexCol justifyCenter alignCenter textCenter">
            <p className="cText">Referral URL</p>
            {Object.keys(dataContent).length > 0 ? (
              <div className=" dFlex justifyCenter alignCenter">
                <p className='linkText'>{shortenAddress(`${localURL}/${dataContent.referral_code}`)}</p>
                <span onClick={() => copyContent(`${localURL}/${dataContent.referral_code}`)}><span className="svgIcon"><CopyIcon /></span></span>
              </div>
            ) : (
              <h2 className="lgHeading">-</h2>
            )}
          </div>
        </Col>
      </Row>
      <ModalComponent
        footer=""
        title={"Transactions Details"}
        openable={showHistory}
        closable={() => setShowHistory(false)}
        widthStyle={768}
      >
        <InvestmentListComponent />
      </ModalComponent>
    </>
  );
};

export default ContentComponent;

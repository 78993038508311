import { createUseStyles } from "react-jss";
import mediaQuery from "assets/breakpoints";
import referralBanner from "assets/images/referralBanner.svg";
import airdropBg from "assets/images/airdropBg.png";
export const useStyles = createUseStyles({
  referralContainer: {
    "& .referralSection": {
      position: "relative",
      overflow: "hidden",
      paddingTop: "0",
      "& .banner": {
        textAlign: "center",
        padding: "50px",
        borderRadius: "20px",
        background: `url(${referralBanner}) no-repeat center center`,
        backgroundSize: "cover",
        width: "100%",
        height: "100%",
        "& h2": {
          position: "relative",
          fontSize: "32px",
          fontWeight: "normal",
          color: "var(--white)",
          fontFamily: "'Good Times', sans-serif;",
          display: "inline-block",
          [mediaQuery.SCREEN_SM_MAX]: {
            fontSize: "21px",
          },
          "&:before": {
            content: "''",
            position: "absolute",
            top: "40%",
            left: "-30px",
            width: "12px",
            height: "12px",
            borderRadius: "50%",
            background: "var(--secondary)",
          },
          "&:after": {
            content: "''",
            position: "absolute",
            top: "40%",
            right: "-30px",
            width: "12px",
            height: "12px",
            borderRadius: "50%",
            background: "var(--secondary)",
          },
        },
        "&>span": {
          maxWidth: "1024px",
          display: "block",
          margin: "30px auto",
          color: "var(--cText)",
        },
        "& p": {
          fontSize: "18px",
          fontWeight: "normal",
          color: "var(--secondary)",
          fontFamily: "'Good Times', sans-serif;",
        },
        "& svg": {
          marginTop: "10px",
          animation: "moveTB 0.5s infinite ease-in-out",
        },
      },
      "& .sBody": {
        background: `url(${airdropBg}) no-repeat center center`,
        marginTop: "100px",
        "& .refSteps": {
          position: "relative",
          "&:after": {
            content: "''",
            position: "absolute",
            top: "0",
            left: "45px",
            width: "0px",
            height: "100%",
            zIndex: "-1",
            borderLeft: "1px dashed var(--cText)",
          },
          "& .refStep": {
            [mediaQuery.SCREEN_SM_MAX]: {
              alignItems: "flex-start",
            },
            "& .left": {
              padding: "10px",
              border: "1px dashed var(--cText)",
              borderRadius: "5px",
              minWidth: "90px",
              borderRadius: "50%",
              minHeight: "90px",
              height: "90px",
              background: "var(--background)",
              "& .cText": {},
              "& .mdHeading": {
                color: "var(--secondary)",
                lineHeight: "25px",
              },
            },
            "& .right": {
              "&>p": {
                marginBottom: "10px",
              },
              "& .example": {
                border: "1px dashed var(--whiteOpacity10)",
                padding: "10px",
                width: "fit-content",
                marginTop: "10px",
                borderRadius: "5px",
                "& p": {
                  fontWeight: "400",
                  color: "var(--secondary)",
                  "& span": {
                    fontWeight: "500",
                  },
                },
              },
            },
          },
        },
      },
    },
    "& .exampleSection": {
      "& .sBody": {
        "&> .ant-row": {
          "&:nth-child(even)": {
            flexDirection: "row-reverse",
          },
          "&> .ant-col": {
            "&> img": {
              [mediaQuery.SCREEN_LG_MAX]: {
                width: "80%",
              },
              [mediaQuery.SCREEN_MD_MAX]: {
                width: "65%",
                marginTop: "50px",
              },
            },
          },
        },
        "& .steps": {
          width: "75%",
          margin: "0 auto",
          "& .left": {
            "& p": {
              color: "var(--secondary)",
            },
          },
        },
        "& .egBottom": {
          marginTop: "100px",
          "& .card": {
            padding: "20px",
            [mediaQuery.SCREEN_MD_MAX]: {
              marginBottom: "20px",
            },
            "& p": {
              "& span": {
                color: "var(--secondary)",
                fontWeight: "600",
              },
            },
            "&> span": {
              color: "var(--cText)",
              fontWeight: "400",
            },
          },
          "& .egNote": {
            padding: "10px",
            width: "fit-content",
            marginTop: "20px",
            border: "1px dashed var(--whiteOpacity10)",
            borderRadius: "5px",
            [mediaQuery.SCREEN_MD_MAX]: {
              marginTop: "0px",
            },
            "& p": {
              color: "var(--secondary)",
              fontWeight: "300",
              "& span": {
                fontWeight: "500",
              },
            },
          },
        },
      },
    },
  },
});

import { Routes, Route } from "react-router-dom";
import DashboardComponent from "components/DashboardComponent";
import ReferralProgramComponent from 'components/ReferralProgramComponent';
import FooterComponent from '../FooterComponent';
import HeaderComponent from '../HeaderComponent';

const BodyComponent = () => {
  return (
    <div>
      <HeaderComponent />
      <Routes>
        <Route path="/:id" element={<DashboardComponent />} />
        <Route path="/" element={<DashboardComponent />} />
        <Route path="/referral-program" element={<ReferralProgramComponent />} />
      </Routes>
      <FooterComponent />
    </div>
  );
};

export default BodyComponent;

import axios from 'axios'
import { baseURL } from 'utils/env'
import { serverErrors } from './handleServerError'

const commonHeaders = {
  'Content-Type': 'application/json',
  Accept: 'application/json',
}
const getHeader = () => {
  const user = localStorage.getItem("main:user")
  if (user && user) {
    return { ...commonHeaders, ...{ Authorization: '' + user } }
  }
}
export const instanceAxios = axios.create({
  baseURL,
  headers: getHeader(),
})

instanceAxios.interceptors.response.use(
  (response) => successResponce(response),
  (error) => handleErrors(error)
)

instanceAxios.interceptors.request.use(
  (config) => {
    config.headers = getHeader()
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

// add without header
const getWithoutHeader = () => {
  const user = localStorage.getItem("main:user")
  if (user && user) {
    return { ...commonHeaders }
  }
}
export const instanceWithoutAxios = axios.create({
  baseURL,
  headers: getWithoutHeader(),
})

instanceWithoutAxios.interceptors.response.use(
  (response) => successResponce(response),
  (error) => handleErrors(error)
)

instanceWithoutAxios.interceptors.request.use(
  (config) => {
    config.headers = getWithoutHeader()
    return config
  },
  (error) => {
    return Promise.reject(error)
  }
)

const successResponce = (result) => {
  const { data = {} } = result
  return data
}

const handleErrors = (error) => {
  if (
    error &&
    error.response &&
    error.response.status &&
    error.response.status === 401
  ) {
    window.localStorage.clear()
    window.location = '/login'
  }
  return Promise.reject(serverErrors(error))
}
